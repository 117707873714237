import React, { useContext, useEffect, useRef, useState } from 'react';
import './BookingPage.scss';
import { Calendar, Clock, Pencil, User, Users } from '../icons';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TimeRangeButtons from '../components/TimeRangeButtons';
import SliderRange from '../components/SliderRange';
import { useDispatch, useSelector } from 'react-redux';
import { getListAvailableIntervalsEndTime, getListAvailableIntervalsStartTime } from '../services/utils/date';
import format from 'date-fns/format';
import { bookPlace, setActiveInterval } from '../redux/booking';
import { getUsers, openModalUsers } from '../redux/users';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Spinner from '../components/Spinner';
import { addMinutes, set } from 'date-fns';
import { PATH_APP } from '../routes';
import { setBookDay, setTime } from '../redux/date';
import { DeviceAuthContext } from '@inspace-org/react-auth';
import DatePicker from '../components/DatePicker';
import UserSelection from '../components/UserSelection';
import TimeSelection from '../components/TimeSelection';
import * as i18n from 'i18next';
import useDefaultBookingTime from '../hooks/useDefaultBookingTime';
import { sendActionLogs } from '../services/utils/logs';

const validationSchema = yup.object({
  bookDate: yup.string().required(),
  startTime: yup.string().required(),
  endTime: yup.string().required(),
  owner: yup.array().min(1, i18n.t('required-field')),
  participants: yup.array().nullable(),
  meetingName: yup.string(),
});

const BookingPage = ({ placeId, floorId, setOpenNotification }) => {
  const { device, companySettings, settings } = useContext(DeviceAuthContext);
  const { workDayInHours, minBookingInterval } = companySettings;
  const { setDefaultDayTime } = useDefaultBookingTime();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bookDateRef = useRef(null);
  const redux = useSelector((state) => ({
    bookDay: state.date.bookDay,
    startTime: state.date.startTime,
    endTime: state.date.endTime,
    bookingList: state.booking.bookingList,
    availableList: state.booking.availableList,
    activeInterval: state.booking.activeInterval,
    users: state.users.list,
    isLoading: state.booking.isLoading,
    isOpenModalUsers: state.users.isOpenModal,
    placeInfo: state.place.info,
    errors: state.booking.errors,
  }));
  const [togglePicker, setTogglePicker] = useState(false);
  const [activeUsersSelect, setActiveUsersSelect] = useState(null);
  const [openSelectTime, setOpenSelectTime] = useState({
    startTime: false,
    endTime: false,
  });
  const [timeSelectRanges, setTimeSelectRanges] = useState({
    startTime: [],
    endTime: [],
  });
  const [error, setError] = useState(null);
  const initialValues = {
    bookDate: redux.bookDay,
    startTime: redux.startTime,
    endTime: redux.endTime,
    owner: [],
    participants: [],
    meetingName: '',
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: async (values) => {
      sendActionLogsBookingPage('Click submit button');
      const dto = {
        places: device.place,
        initiator: values.owner[0]._id,
        startTime: new Date(values.startTime).toISOString(),
        endTime: new Date(values.endTime).toISOString(),
        guests: values.participants.map((item) => item._id),
        title: values.meetingName,
        zoomLink: values.owner[0].zoomLink,
      };
      try {
        await dispatch(bookPlace(dto));
        setOpenNotification(true);
      } catch (err) {
        console.error(err);
      }
    },
  });

  useEffect(() => {
    if (bookDateRef.current) {
      const closePicker = (e) =>
        bookDateRef.current && !bookDateRef.current.contains(e.target) && setTogglePicker(false);
      document.addEventListener('click', closePicker);
      return () => document.removeEventListener('click', closePicker);
    }
  }, [bookDateRef.current]);

  useEffect(() => {
    let timer;
    if (error) {
      timer = setTimeout(() => {
        setError(null);
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [error]);

  useEffect(() => {
    if (typeof redux.activeInterval === 'number') {
      const newStartTime =
        new Date(redux.startTime || redux.bookDay).getTime() < new Date().getTime()
          ? new Date()
          : new Date(redux.startTime || redux.bookDay);
      const newEndTime = addMinutes(newStartTime, redux.activeInterval);
      dispatch(
        setTime({
          startTime: set(new Date(redux.bookDay), {
            hours: newStartTime.getHours(),
            minutes: newStartTime.getMinutes(),
            seconds: 0,
          }),
          endTime: set(new Date(redux.bookDay), {
            hours: newEndTime.getHours(),
            minutes: newEndTime.getMinutes(),
            seconds: 0,
          }),
        }),
      );
    }
  }, [redux.activeInterval]);

  useEffect(() => {
    const startTimeRange = getListAvailableIntervalsStartTime(new Date(redux.bookDay), [], minBookingInterval || 15);
    const endTimeRange = getListAvailableIntervalsEndTime(
      new Date(redux.bookDay),
      [],
      formik.values.startTime,
      minBookingInterval || 15,
    );
    if (startTimeRange.length) {
      setTimeSelectRanges((prev) => ({
        ...prev,
        startTime: startTimeRange,
        endTime: endTimeRange,
      }));
      if (new Date(formik.values.startTime).getTime() > new Date(formik.values.endTime).getTime()) {
        dispatch(
          setTime({
            startTime: formik.values.startTime,
            endTime: endTimeRange[0],
          }),
        );
      }
    }
  }, [redux.bookDay, formik.values.startTime, minBookingInterval]);

  useEffect(() => {
    if (!redux.activeInterval) {
      setDefaultDayTime(redux.bookDay, redux.availableList, redux.bookingList);
    }
  }, [redux.availableList, redux.bookingList]);

  useEffect(() => {
    if (redux.startTime && redux.endTime) {
      formik.setFieldValue('startTime', redux.startTime);
      formik.setFieldValue('endTime', redux.endTime);
    }
  }, [redux.bookingList]);

  useEffect(() => {
    if (redux.startTime) {
      formik.setFieldValue('startTime', redux.startTime);
    }
  }, [redux.startTime]);

  useEffect(() => {
    if (redux.endTime) {
      formik.setFieldValue('endTime', redux.endTime);
    }
  }, [redux.endTime]);

  useEffect(() => {
    if (redux.bookDay.toString() !== formik.values.bookDate.toString()) {
      formik.setFieldValue('bookDate', redux.bookDay);
    }
  }, [redux.bookDay]);

  useEffect(() => {
    dispatch(setBookDay(formik.values.bookDate));
  }, [formik.values.bookDate, workDayInHours]);

  const handelChangeUsers = (users, type) => {
    sendActionLogsBookingPage(`Click to select user in list (${type} field)`);
    dispatch(openModalUsers(!redux.isOpenModalUsers));
    formik.setFieldValue(type, users);
    setActiveUsersSelect(null);
  };

  const handleOpenUsersList = async (e, type) => {
    sendActionLogsBookingPage(`Click to toggle select (${type} field)`);
    try {
      await dispatch(openModalUsers(!redux.isOpenModalUsers));
      if (!activeUsersSelect) {
        setActiveUsersSelect(type);
        await dispatch(getUsers());
      } else {
        setActiveUsersSelect(null);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleOpenInput = (type) => {
    sendActionLogsBookingPage(`Click to open input field ${type}`);
    setActiveUsersSelect(type);
  };

  const handleTogglePicker = () => {
    sendActionLogsBookingPage('Click to toggle date picker (Date field)');
    setTogglePicker(!togglePicker);
    setOpenSelectTime({
      startTime: false,
      endTime: false,
    });
  };

  const handleChangeBookDate = (e) => {
    sendActionLogsBookingPage('Click to select date in date picker (Date field)');
    setTogglePicker(false);
    formik.setFieldValue('bookDate', new Date(e));
  };

  const handelClickSelectTime = (type) => {
    sendActionLogsBookingPage(`Click to select time in ${type} field`);
    setTogglePicker(false);
    setOpenSelectTime({
      startTime: type === 'startTime' ? !openSelectTime[type] : false,
      endTime: type === 'endTime' ? !openSelectTime[type] : false,
    });
  };

  const handelSelectStartTime = (e) => {
    const value = e.target.attributes.value.value;
    sendActionLogsBookingPage('Click Stat Time field');
    dispatch(
      setTime({
        startTime: value,
        endTime: formik.values.endTime,
      }),
    );
    dispatch(setActiveInterval(null));
    setOpenSelectTime({
      ...openSelectTime,
      startTime: false,
    });
  };

  const handelSelectEndTime = (e) => {
    const value = e.target.attributes.value.value;
    sendActionLogsBookingPage('Click End Time field');
    dispatch(
      setTime({
        startTime: formik.values.startTime,
        endTime: value,
      }),
    );
    dispatch(setActiveInterval(null));
    setOpenSelectTime({
      ...openSelectTime,
      endTime: false,
    });
  };

  const sendActionLogsBookingPage = (actionName) => {
    sendActionLogs({
      placeInfo: redux.placeInfo,
      errors: redux.errors,
      device,
      companySettings,
      settings,
      actionName: actionName,
      actionPath: window.location.pathname,
    });
  };

  const hasError = (field) => Boolean(formik.touched[field] && formik.errors[field]);
  const helperTextFor = (field, defaultText) => (hasError(field) ? formik.errors[field] : defaultText);

  return (
    <div className={`content_item-booking ${activeUsersSelect ? 'hiddenAllBookingFormElem' : ''}`}>
      <button
        className="button-back visible"
        onClick={() => {
          sendActionLogsBookingPage('Click back arrow');
          navigate(PATH_APP.room);
        }}
      >
        <div className="button-back_arrow" />
        <div>
          <Trans i18nKey="form.title" />
        </div>
      </button>
      <div className="form">
        {redux.isLoading ? (
          <Spinner />
        ) : (
          <form onSubmit={formik.handleSubmit} noValidate>
            <div className="form_section visible">
              <ul className="inputs">
                <li className={`${hasError('bookDate') ? 'inputs_error' : ''}inputs_item`}>
                  <label className="label" htmlFor="date">
                    <Trans i18nKey="form.date" />
                  </label>
                  <div className="input_date-picker" ref={bookDateRef}>
                    <div className="input_date" onClick={handleTogglePicker} />
                    {togglePicker && (
                      <DatePicker initialDate={formik.values.bookDate} onChange={handleChangeBookDate} />
                    )}
                    <div className="input_icon">
                      <Calendar />
                    </div>
                    {format(new Date(formik.values.bookDate), 'E MMM d')}
                  </div>
                  {helperTextFor('bookDate') && <div className="error">{helperTextFor('bookDate')}</div>}
                </li>
                <li className={`${hasError('startTime') ? 'inputs_error' : ''} inputs_item`}>
                  <label className="label" htmlFor="start-time">
                    <Trans i18nKey="form.start" />
                  </label>
                  <div className="input_wrapper">
                    <div
                      id="start-time"
                      className="input_elem input_select_time"
                      onClick={() => handelClickSelectTime('startTime')}
                    >
                      {formik.values.startTime ? format(new Date(formik.values.startTime), 'h:mm a') : ''}
                    </div>
                    {openSelectTime.startTime && (
                      <TimeSelection
                        scrollAfter={formik.values.startTime}
                        options={timeSelectRanges.startTime}
                        onClick={handelSelectStartTime}
                        open={openSelectTime.startTime}
                      />
                    )}
                    <div className="input_icon">
                      <Clock />
                    </div>
                  </div>
                  {helperTextFor('startTime') && <div className="error">{helperTextFor('startTime')}</div>}
                </li>
                <li className={`${hasError('endTime') ? 'inputs_error' : ''} inputs_item`}>
                  <label className="label" htmlFor="end-time">
                    <Trans i18nKey="form.end" />
                  </label>
                  <div className="input_wrapper">
                    <div
                      id="end-time"
                      className="input_elem input_select_time"
                      onClick={() => handelClickSelectTime('endTime')}
                    >
                      {formik.values.endTime ? format(new Date(formik.values.endTime), 'h:mm a') : ''}
                    </div>
                    {openSelectTime.endTime && (
                      <TimeSelection
                        scrollAfter={formik.values.endTime}
                        options={timeSelectRanges.endTime}
                        onClick={handelSelectEndTime}
                        open={openSelectTime.endTime}
                      />
                    )}
                    <div className="input_icon">
                      <Clock />
                    </div>
                  </div>
                  {helperTextFor('endTime') && <div className="error">{helperTextFor('endTime')}</div>}
                </li>
              </ul>
            </div>
            <div className="form_section slider_section visible">
              <div className="label">
                <Trans i18nKey="form.slider" />
              </div>
              <div className="from_slider">
                <SliderRange
                  bookDay={redux.bookDay}
                  activeInterval={redux.activeInterval}
                  workDayInHours={workDayInHours}
                  sendActionLogsBookingPage={sendActionLogsBookingPage}
                />
              </div>
              <div className="form_buttons">
                <TimeRangeButtons
                  className="form_buttons--range"
                  device={device}
                  companySettings={companySettings}
                  settings={settings}
                />
              </div>
            </div>
            <div className="form_section">
              <ul className="inputs">
                <UserSelection
                  type="owner"
                  iconNode={<User fill="#A1AEB7" />}
                  value={formik.values.owner}
                  helperText={helperTextFor}
                  hasError={hasError}
                  onClick={handleOpenUsersList}
                  onChange={handelChangeUsers}
                  label="form.user.label"
                  placeholder="form.user.placeholder"
                  active={activeUsersSelect === 'owner'}
                  sendActionLogsBookingPage={sendActionLogsBookingPage}
                />
                <UserSelection
                  type="participants"
                  iconNode={<Users />}
                  helperText={helperTextFor}
                  value={formik.values.participants}
                  hasError={hasError}
                  onClick={handleOpenUsersList}
                  onChange={handelChangeUsers}
                  label="form.users.label"
                  placeholder="form.users.placeholder"
                  active={activeUsersSelect === 'participants'}
                  isMulti
                  sendActionLogsBookingPage={sendActionLogsBookingPage}
                />
              </ul>
              <div
                className={`input input_up ${
                  activeUsersSelect === 'meeting-name' ? `active-block-meeting-name` : 'visible'
                } ${hasError('meetingName') ? 'inputs_error' : ''}`}
              >
                <label className="label" htmlFor="meeting-name">
                  <Trans i18nKey="form.meeting_name.label" />
                </label>
                <div className="input_wrapper" onClick={() => handleOpenInput('meeting-name')}>
                  <input
                    className="input_elem"
                    type="text"
                    name="meetingName"
                    placeholder={t('form.meeting_name.placeholder')}
                    value={formik.values.meetingName}
                    onChange={formik.handleChange}
                  />
                  <div className="input_icon">
                    <Pencil />
                  </div>
                </div>
                {helperTextFor('meetingName') && <div className="error">{helperTextFor('meetingName')}</div>}
                {activeUsersSelect === 'meeting-name' && (
                  <div className="users_buttons">
                    <button type="button" className="gradient-border" onClick={() => setActiveUsersSelect(null)}>
                      <p className="users_buttons-cancel">
                        <Trans i18nKey="form.users.btn_cancel" />
                      </p>
                    </button>
                    <button type="button" className="users_buttons-done" onClick={() => setActiveUsersSelect(null)}>
                      <Trans i18nKey="form.users.btn_done" />
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="form_submit visible">
              <button type="submit">{formik.isSubmitting ? <Spinner /> : <Trans i18nKey="form.book_btn" />}</button>
              {error && <div className="form_error">{error}</div>}
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default BookingPage;
