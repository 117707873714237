import React, { useContext, useEffect, useState } from 'react';
import './EventsPicker.scss';
import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import add from 'date-fns/add';
import set from 'date-fns/set';
import Spinner from './Spinner';
import { getBookingsUsers, setActiveInterval, setAvailableTimeList } from '../redux/booking';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, ArrowRight } from '../icons';
import { PATH_APP as PATH_APP } from '../routes';
import { DeviceAuthContext } from '@inspace-org/react-auth';
import { setBookDay, setTime } from '../redux/date';
import { Trans } from 'react-i18next';
import { INTERVALS } from '../const/date';
import { getAvailableTime } from '../services/utils/date';
import useDefaultBookingTime from '../hooks/useDefaultBookingTime';
import { addDays, isAfter } from 'date-fns';
import { sendActionLogs } from '../services/utils/logs';

const EventsPicker = ({ isBookingPage }) => {
  const { device, companySettings, settings } = useContext(DeviceAuthContext);
  const { getEndWorkDay, getStartWorkDay, maxBookingDate } = useDefaultBookingTime();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const redux = useSelector((state) => ({
    bookDay: state.date.bookDay,
    startTime: state.date.startTime,
    endTime: state.date.endTime,
    bookingList: state.booking.bookingList,
    availableList: state.booking.availableList,
    isLoading: state.booking.isLoading,
    isOpenModalUsers: state.users.isOpenModal,
    placeInfo: state.place.info,
    errors: state.booking.errors,
  }));
  const [date, setDate] = useState(null);
  const [bookings, setBookings] = useState([]);
  const setDisabledDate = () => {
    return (
      (companySettings.maxBookingStartLimitedBy === 'date' && addDays(redux.bookDay, 1) >= maxBookingDate) ||
      (companySettings.maxBookingStartLimitedBy === 'offset' && addDays(redux.bookDay, 2) >= maxBookingDate)
    );
  };

  const [isNotAllowedDate, setNotAllowedDate] = useState(setDisabledDate());

  useEffect(() => {
    setNotAllowedDate(setDisabledDate());
  }, [redux.bookDay]);

  useEffect(() => {
    setBookings(generationBookingsData());
  }, [redux.bookingList, redux.availableList]);

  useEffect(() => {
    if (device.floor && device.place) {
      const interval = setInterval(() => {
        updateBookings();
        setBookings(generationBookingsData());
      }, 60000);
      return () => {
        clearInterval(interval);
      };
    }
  });

  useEffect(() => {
    const startDay = getStartWorkDay(redux.bookDay);
    const endDay = getEndWorkDay(redux.bookDay);
    dispatch(setAvailableTimeList(getAvailableTime(redux.bookingList, redux.bookDay, startDay, endDay)));
  }, [redux.bookingList]);

  useEffect(() => {
    if (redux.bookDay) {
      setDate(redux.bookDay);
      getBookings();
      setBookings(generationBookingsData());
    }
  }, [redux.bookDay]);

  const sortBookings = (bookings) => {
    return bookings
      .sort((a, b) => {
        if (new Date(a.startTime) > new Date(b.startTime)) {
          return 1;
        }
        if (new Date(a.startTime) < new Date(b.startTime)) {
          return -1;
        }
        return 0;
      })
      .filter((el) => new Date(el.endTime) > new Date());
  };

  const generationBookingsData = () => {
    if (isAfter(new Date(), maxBookingDate)) return [];
    const bookingsData = [];
    redux.bookingList.forEach((el) => {
      bookingsData.push({
        visibility: el.visibility,
        startTime: el.startTime,
        endTime: el.endTime,
        own: el.userEntity ? `${el.userEntity.firstName} ${el.userEntity.lastName}` : '',
        isAvailable: false,
      });
    });
    redux.availableList.forEach((el) => {
      bookingsData.push({
        startTime: el.startTime,
        endTime: el.endTime,
        own: '',
        description: 'Book Now',
        isAvailable: true,
      });
    });
    return sortBookings(bookingsData);
  };

  const updateBookings = () => {
    if (new Date().getDate() !== redux.bookDay.getDate()) {
      dispatch(setBookDay(new Date()));
      getBookings(new Date());
    } else {
      getBookings();
    }
  };

  const getBookings = async (bookDate) => {
    try {
      const newBookDay = bookDate ? bookDate : redux.bookDay;
      await dispatch(
        getBookingsUsers({
          floorId: device.floor,
          placeId: device.place,
          params: {
            bookDate: newBookDay,
            startTime: set(new Date(newBookDay), { hours: 0, minutes: 0, seconds: 5 }).toISOString(),
            endTime: set(new Date(newBookDay), { hours: 23, minutes: 59, seconds: 55 }).toISOString(),
          },
        }),
      );
    } catch (err) {
      console.error(err);
    }
  };

  const handleChangeDate = (side) => {
    sendActionLogs({
      placeInfo: redux.placeInfo,
      errors: redux.errors,
      device,
      companySettings,
      settings,
      actionName: `Event picker, click on arrow ${side}`,
      actionPath: window.location.pathname,
    });
    dispatch(setActiveInterval(null));
    if (side === 'right') {
      const nextDate = add(new Date(date), {
        days: 1,
      });
      dispatch(setBookDay(nextDate));
    } else {
      const previousDate = add(new Date(date), {
        days: -1,
      });
      dispatch(setBookDay(previousDate));
    }
  };

  const handleClickAvailableTime = (item) => {
    if (item.isAvailable) {
      sendActionLogs({
        placeInfo: redux.placeInfo,
        errors: redux.errors,
        device,
        companySettings,
        settings,
        actionName: `Event picker, click on available range time`,
        actionPath: window.location.pathname,
      });
      dispatch(setActiveInterval(INTERVALS.intervalAvailable));
      dispatch(setTime({ startTime: item.startTime, endTime: item.endTime }));
      !isBookingPage && navigate(PATH_APP.booking);
    }
  };

  return (
    <div className="events">
      {redux.isOpenModalUsers && isBookingPage ? (
        <></>
      ) : (
        <>
          <div className={`events-slider_title ${!isBookingPage ? 'hidden' : ''}`}>
            <Trans i18nKey="events_list_title" />
          </div>
          <div className="events-slider">
            <button
              className="events-slider_arrow"
              onClick={() => handleChangeDate('left')}
              disabled={isToday(new Date(date))}
            >
              <ArrowLeft disabled={isToday(new Date(date))} />
            </button>
            <div className="events-slider_date">{`${isToday(new Date(date)) ? 'TODAY,' : ''} ${format(
              new Date(date),
              'E, LLL d',
            )}`}</div>
            <button
              className="events-slider_arrow"
              onClick={() => handleChangeDate('right')}
              disabled={isNotAllowedDate}
            >
              <ArrowRight disabled={isNotAllowedDate} />
            </button>
          </div>
          <ul className="events_list">
            {redux.isLoading ? (
              <div className="events_spinner">
                <Spinner />
              </div>
            ) : (
              <>
                {bookings.map((item, i) => (
                  <li
                    className={`events_item ${item.isAvailable ? 'gradient-border' : 'event'}`}
                    key={i}
                    onClick={() => handleClickAvailableTime(item)}
                  >
                    {item.isAvailable ? (
                      <div className="event_link">
                        <div className="event_wrapper">
                          <div className="event_time">{`${format(new Date(item.startTime), 'h:mm aa')} - ${format(
                            new Date(item.endTime),
                            'h:mm aa',
                          )}`}</div>
                          {item?.visibility?.details === 'private' ? (
                            <div className="event_private">
                              <Trans i18nKey="event_private" />
                            </div>
                          ) : (
                            <>
                              {item.description && <div className="event_text">{item.description}</div>}
                              {item.own && (
                                <>
                                  <div className="event_line" />
                                  <div className="event_name">{item.own}</div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="event_link">
                        <div className="event_wrapper">
                          <div className="event_time">{`${format(new Date(item.startTime), 'h:mm aa')} - ${format(
                            new Date(item.endTime),
                            'h:mm aa',
                          )}`}</div>
                          {item?.visibility?.details === 'private' ? (
                            <div className="event_private">
                              <Trans i18nKey="event_private" />
                            </div>
                          ) : (
                            <>
                              {item.description && <div className="event_text">{item.description}</div>}
                              {item.own && (
                                <>
                                  <div className="event_line" />
                                  <div className="event_name">{item.own}</div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    {i === 0 && isToday(new Date(date)) && (
                      <div className={`event_dote ${item.isAvailable ? 'event_dote--gradient' : 'event_dote--grey'}`} />
                    )}
                  </li>
                ))}
              </>
            )}
          </ul>
        </>
      )}
    </div>
  );
};

export default EventsPicker;
