import React, { useContext, useEffect, useState } from 'react';
import './RoomPage.scss';
import { Trans, useTranslation } from 'react-i18next';
import TimeRangeButtons from '../components/TimeRangeButtons';
import { useDispatch, useSelector } from 'react-redux';
import { bookCheckIn } from '../redux/booking';
import add from 'date-fns/add';
import isToday from 'date-fns/isToday';
import { openModalUsers } from '../redux/users';
import { DeviceAuthContext } from '@inspace-org/react-auth';
import { sendActionLogs } from '../services/utils/logs';

const RoomPage = ({ isAvailable }) => {
  const { device, settings, companySettings } = useContext(DeviceAuthContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [filters, setFilters] = useState('');
  const [showCheckInBtn, setShowCheckInBtn] = useState(false);
  const redux = useSelector((state) => ({
    placeInfo: state.place.info,
    booking: state.booking.bookingList,
    bookDay: state.date.bookDay,
  }));

  useEffect(() => {
    dispatch(openModalUsers(false));
  }, []);

  useEffect(() => {
    setFilters(getFilters(redux.placeInfo.filters));
  }, [redux.placeInfo]);

  useEffect(() => {
    showCheckInButton();
  }, [redux.booking, redux.bookDay]);

  const showCheckInButton = () => {
    if (!redux.booking.length) return;
    const nearestBooking = add(new Date(redux.booking[0].startTime), {
      minutes: -15,
    });
    if (
      isToday(nearestBooking) &&
      new Date().toString() >= nearestBooking.toString() &&
      new Date().toString() <= new Date(redux.booking[0].startTime).toString()
    ) {
      setShowCheckInBtn(true);
    } else {
      setShowCheckInBtn(false);
    }
  };

  const getFilters = (filters) => {
    const newFilters = [];
    filters.forEach((fl) => {
      if (fl.type === 'boolean' && fl.valueBoolean) {
        newFilters.push(fl.title);
      } else if (redux.placeInfo.forGroup && fl._id === 'SYSTEM_PLACE_CAPACITY') {
        newFilters.push(`${fl.valueNumber} ${t('filter_seats', { count: fl.valueNumber })}`);
      } else if (fl.type === 'number' && fl.valueNumber > 0 && redux.placeInfo.forGroup) {
        newFilters.push(`${fl.valueNumber} ${fl.title}`);
      } else if (fl.type === 'string' && fl.valueString !== '') {
        newFilters.push(fl.valueString);
      }
    });
    return newFilters.join(' • ');
  };

  const handleClickCheckIn = async (e) => {
    sendActionLogs({
      placeInfo: redux.placeInfo,
      errors: redux.errors,
      device,
      companySettings,
      settings,
      actionName: `Click check-in button`,
      actionPath: window.location.pathname,
    });
    await dispatch(bookCheckIn(redux.placeInfo.nfcId));
    setShowCheckInBtn(false);
  };

  return (
    <div className="content_item-wrapper">
      <h1 className="status">
        {isAvailable ? <Trans i18nKey="available_status" /> : <Trans i18nKey="booked_status" />}
      </h1>
      <p className="name">
        <Trans
          i18nKey="meeting_room_title"
          values={{
            title: redux.placeInfo.title,
          }}
        />
      </p>
      <div className="filters">{filters}</div>
      <div className="button">
        {showCheckInBtn && (
          <button className="button_checkIn" onClick={handleClickCheckIn}>
            <Trans i18nKey="check_in_btn" />
          </button>
        )}
      </div>
      <TimeRangeButtons
        title="button_interval_title"
        device={device}
        settings={settings}
        companySettings={companySettings}
      />
    </div>
  );
};

export default RoomPage;
