import set from 'date-fns/set';
import env from '../env';

let language = 'en';

if (window.navigator.language.slice(0, 2) === 'ru') {
  language = 'ru';
}

if (env.locale.force) {
  language = env.locale.force;
}
export const LOCALE_TIME_FMT = {
  hour: 'numeric',
  minute: '2-digit',
  hour12: language === 'en',
};
export const INTERVALS = {
  interval15: 15,
  interval30: 30,
  interval60: 60,
  intervalAvailable: 'Available',
};
