import React from 'react';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter } from 'react-router-dom';
import { DeviceAuthContextProvider } from '@inspace-org/react-auth';
import { apiCredentials, apiUrl } from './services/http';
import { language } from './localization';
import ErrorBoundary from './components/ErrorBoundary';
import { ExtraErrorData } from '@sentry/integrations';

Sentry.init({
  dsn: 'https://98dcc07017b5522cfc15d86e51260404@o4505722220118016.ingest.sentry.io/4506428324970496',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['https://test-tablet.inspace.app', 'https://tablet.inspace.app'],
    }),
    new ExtraErrorData({ depth: 10 }),
    new Sentry.Replay(),
  ],

  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ErrorBoundary>
    <DeviceAuthContextProvider apiUrl={apiUrl} credentials={apiCredentials} language={language}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </DeviceAuthContextProvider>
  </ErrorBoundary>,
);
