import React, { useContext, useEffect, useState } from 'react';
import './MainLayout.scss';
import Header from '../components/Header';
import FullscreenButton from '../components/FullscreenButton';
import EventsPicker from '../components/EventsPicker';
import { useDispatch, useSelector } from 'react-redux';
import { getPlaceInfo } from '../redux/place';
import { DeviceAuthContext } from '@inspace-org/react-auth';
import NotificationPage from '../components/NotificationPage';
import format from 'date-fns/format';
import { api } from '../services/http';
import { setErrorText } from '../redux/booking';
import { sendActionLogs, sendLogs } from '../services/utils/logs';
import * as Sentry from '@sentry/react';

const TIME_FOR_RELOAD = '3:00 AM';

const MainLayout = ({ children, isAvailable, openNotification, setOpenNotification }) => {
  const { device, branding, settings, companySettings } = useContext(DeviceAuthContext);
  const dispatch = useDispatch();
  const redux = useSelector((state) => ({
    errors: state.booking.errors,
    placeInfo: state.place.info,
  }));
  const [isBookingPage, setBookingPage] = useState(window.location.pathname.indexOf('booking') !== -1);

  useEffect(() => {
    if (device) {
      window.addEventListener('focus', (e) => {
        sendActionLogs({
          placeInfo: redux.placeInfo,
          errors: redux.errors,
          device,
          companySettings,
          settings,
          actionName: 'Focus action, open or expand a tab or browser',
          actionPath: window.location.pathname,
        });
      });
      window.addEventListener('blur', (e) => {
        sendActionLogs({
          placeInfo: redux.placeInfo,
          errors: redux.errors,
          device,
          companySettings,
          settings,
          actionName: 'Blur action, close or collapse a tab or browser',
          actionPath: window.location.pathname,
        });
      });
    }
  }, [device]);

  window.addEventListener('offline', () => {
    dispatch(
      setErrorText({
        message: 'Unstable connection has caused an error to occur. Please check your internet connection.',
      }),
    );
  });

  window.addEventListener('online', () => {
    dispatch(setErrorText(null));
  });

  const getInfoAboutPlace = async () => {
    try {
      if (device && device.place) {
        await dispatch(getPlaceInfo(device.place));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const checkReloadPage = () => {
    const time = format(new Date(), 'h:mm aa');
    if (TIME_FOR_RELOAD === time) {
      let timer;
      const randomMilliseconds = Math.floor(60000 + Math.random() * (1800000 + 1 - 60000)); // from 1 min to 30 min
      try {
        sendActionLogs({
          placeInfo: redux.placeInfo,
          errors: redux.errors,
          device,
          companySettings,
          settings,
          actionName: 'Update is in progress',
          actionPath: 'MainLayout component',
        });
        timer = setTimeout(async () => {
          await api.get('/v1/auth/check').then((res) => {
            if (res?.device?._id) window.location.reload();
          });
        }, randomMilliseconds);
      } catch (err) {
        Sentry.captureException(err);
        console.error(err);
        sendActionLogs({
          placeInfo: redux.placeInfo,
          errors: err,
          device,
          companySettings,
          settings,
          actionName: 'Error auth/check',
          actionPath: 'MainLayout component',
        });
      }
      return () => clearTimeout(timer);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => checkReloadPage(), 40000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    getInfoAboutPlace();
  }, [device]);

  useEffect(() => {
    setBookingPage(window.location.pathname.indexOf('booking') !== -1);
  }, [window.location.pathname]);

  useEffect(() => {
    let intervalSendingLogs;
    if (redux.placeInfo.number && device) {
      sendLogs({ placeInfo: redux.placeInfo, errors: redux.errors, device, companySettings, settings });
      intervalSendingLogs = setInterval(
        () => sendLogs({ placeInfo: redux.placeInfo, errors: redux.errors, device, companySettings, settings }),
        300000,
      );
    }
    return () => clearInterval(intervalSendingLogs);
  }, [redux.placeInfo]);

  return (
    <div className={`container ${!isBookingPage ? (isAvailable ? 'container--available' : 'container--booked') : ''}`}>
      <div className="wrapper">
        {openNotification ? (
          <NotificationPage errors={redux.errors} />
        ) : (
          <>
            <Header logoImg={branding?.fullLogoDark} isActiveBranding={branding?.isEnabled} />
            <div className="content">
              <div className={`content_item ${isBookingPage ? 'content_item--border' : ''}`}>
                {children}
                <FullscreenButton
                  device={device}
                  companySettings={companySettings}
                  settings={settings}
                  placeInfo={redux.placeInfo}
                />
              </div>
              <div className="content_item">
                {companySettings ? <EventsPicker isBookingPage={isBookingPage} /> : ''}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MainLayout;
