import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import './TimeRangeButtons.scss';
import { setActiveInterval } from '../redux/booking';
import { INTERVALS } from '../const/date';
import { useNavigate } from 'react-router-dom';
import { PATH_APP } from '../routes';
import { isAfter } from 'date-fns';
import useDefaultBookingTime from '../hooks/useDefaultBookingTime';
import { sendActionLogs } from '../services/utils/logs';

const TimeRangeButtons = ({ title, className, device, companySettings, settings }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { maxBookingDate } = useDefaultBookingTime();
  const redux = useSelector((state) => ({
    activeInterval: state.booking.activeInterval,
    placeInfo: state.place.info,
  }));
  const [activeInt, setActiveInt] = useState(redux.activeInterval);
  const isBookingPage = window.location.pathname.indexOf('booking') !== -1;
  const disabledBtn = isAfter(new Date(), maxBookingDate);

  const handleClickBookBtn = (interval) => {
    sendActionLogs({
      placeInfo: redux.placeInfo,
      errors: redux.errors,
      device,
      companySettings,
      settings,
      actionName: `Click on button interval ${interval}`,
      actionPath: window.location.pathname,
    });
    if (!isBookingPage && !disabledBtn) {
      navigate(PATH_APP.booking);
    }
    dispatch(setActiveInterval(interval));
  };

  const setGradientClass = (interval) => {
    if (!isBookingPage && !disabledBtn) return 'gradient-border';
    return interval === activeInt && !disabledBtn ? 'gradient-border' : 'buttons-interval_border';
  };

  useEffect(() => {
    setActiveInt(redux.activeInterval);
  }, [redux.activeInterval]);

  return (
    <div className={`buttons-interval ${className}`}>
      {title && (
        <div className="buttons-interval_title">
          <Trans i18nKey={title} />
        </div>
      )}
      <ul className="buttons-interval_list">
        <li className={`buttons-interval_item ${setGradientClass(INTERVALS.interval15)}`}>
          <button
            type="button"
            className={`buttons-interval_btn`}
            onClick={() => handleClickBookBtn(INTERVALS.interval15)}
          >
            <Trans i18nKey="button_interval_15" />
          </button>
        </li>
        <li className={`buttons-interval_item ${setGradientClass(INTERVALS.interval30)}`}>
          <button
            type="button"
            className={`buttons-interval_btn`}
            onClick={() => handleClickBookBtn(INTERVALS.interval30)}
          >
            <Trans i18nKey="button_interval_30" />
          </button>
        </li>
        <li className={`buttons-interval_item ${setGradientClass(INTERVALS.interval60)}`}>
          <button
            type="button"
            className={`buttons-interval_btn`}
            onClick={() => handleClickBookBtn(INTERVALS.interval60)}
          >
            <Trans i18nKey="button_interval_60" />
          </button>
        </li>
      </ul>
    </div>
  );
};

export default TimeRangeButtons;
