import React from 'react';

export const Pencil = (props) => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M26.4222 9.55938L28.0118 8.00185C28.8146 7.19948 28.7044 5.56012 27.9961 4.83642L27.4609 4.30151C26.7527 3.59354 25.5691 3.24115 24.7664 4.01205L23.1767 5.58531L26.4222 9.55938ZM7.86532 28.061L25.0371 10.9124L21.8231 6.93832L4.63558 24.1026L3.45826 28.0263C3.31661 28.4039 3.71009 28.8444 4.08784 28.6871L7.86532 28.061Z"
        fill="#A1AEB7"
      />
    </svg>
  );
};
