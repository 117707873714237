import React, { useContext, useEffect, useRef, useState } from 'react';
import './SliderRange.scss';
import MultiRangeSlider from './MultiRangeSlider';
import { convertHoursInMinutes, convertMinutesInHours, getAvailableTime } from '../services/utils/date';
import format from 'date-fns/format';
import set from 'date-fns/set';
import { useDispatch, useSelector } from 'react-redux';
import { setTime } from '../redux/date';
import { find } from 'lodash';
import { setActiveInterval } from '../redux/booking';
import useDefaultBookingTime from '../hooks/useDefaultBookingTime';
import { DeviceAuthContext } from '@inspace-org/react-auth';

const SliderRange = ({ bookDay, activeInterval, sendActionLogsBookingPage }) => {
  const dispatch = useDispatch();
  const { workSchedule } = useDefaultBookingTime();
  const sliderRef = useRef();
  const [valuesScale, setValuesScale] = useState([]);
  const { getStartWorkDay, getEndWorkDay } = useDefaultBookingTime();
  const [bookings, setBookings] = useState([]);
  const [workingHours, setWorkingHours] = useState([]);

  const redux = useSelector((state) => ({
    startTime: state.date.startTime,
    endTime: state.date.endTime,
    allBookingsList: state.booking.allBookingsList,
  }));

  useEffect(() => {
    if (sliderRef.current) {
      setValuesScale(sliderRef.current.nextElementSibling.childNodes);
    }
  }, []);

  useEffect(() => {
    setWorkingHours(getTrackWithWorkHour());
  }, [workSchedule, bookDay]);

  useEffect(() => {
    setBookings(transformBookings());
  }, [redux.allBookingsList, activeInterval]);

  useEffect(() => {
    const resizeFn = () => setBookings(transformBookings());
    window.addEventListener('resize', resizeFn);
    return () => {
      window.removeEventListener('resize', resizeFn);
    };
  }, [redux.allBookingsList]);

  const getPositionRange = (min, max) => {
    if (!valuesScale.length) return null;
    const widthBtwScaleValues = valuesScale[1].offsetLeft - valuesScale[0].offsetLeft;
    const minValueScale = find(valuesScale, (el) => el.attributes[0].value >= min);
    if (minValueScale) {
      const percentFilledScale = ((+minValueScale.attributes[0].value - min) / 60) * 100;
      const width = (widthBtwScaleValues * percentFilledScale) / 100;
      const offsetLeftStart = minValueScale.offsetLeft - width;
      return `${offsetLeftStart}px`;
    }
  };

  const getWidthRange = (min, max) => {
    if (!valuesScale.length) return null;
    const minValueScale = find(valuesScale, (el) => el.attributes[0].value <= min);
    const maxValueScale = find(valuesScale, (el) => el.attributes[0].value >= max);
    if (minValueScale && maxValueScale) {
      const fullWidthScale = maxValueScale.offsetLeft - minValueScale.offsetLeft;
      const percentFilledScale =
        ((max - min) / (maxValueScale.attributes[0].value - minValueScale.attributes[0].value)) * 100;
      const widthRange = (fullWidthScale * percentFilledScale) / 100 + maxValueScale.offsetWidth;
      return `${widthRange}px`;
    }
  };

  const getTrackWithWorkHour = () => {
    const result = [];
    const startDay = getStartWorkDay(bookDay);
    const endDay = getEndWorkDay(bookDay);
    let workHours = endDay.getHours() - startDay.getHours();
    const startSlide = convertHoursInMinutes(startDay);
    for (let i = 0; i <= workHours; i++) {
      result.push({
        minutes: startSlide + 60 * i,
        hour: set(new Date(), { hours: startDay.getHours() + i, minutes: 0, seconds: 0 }),
      });
    }
    return result;
  };

  const transformBookings = () => {
    const startDay = getStartWorkDay(bookDay);
    const endDay = getEndWorkDay(bookDay);
    const availableLists = getAvailableTime(redux.allBookingsList, bookDay, startDay, endDay, true);
    if (!availableLists.length) return [];
    return availableLists.map((el, i) => {
      const min = convertHoursInMinutes(el.startTime);
      const max = convertHoursInMinutes(el.endTime);
      return {
        min,
        max,
      };
    });
  };

  const handelChange = (value) => {
    if (value.min && value.max) {
      const startTimeArr = convertMinutesInHours(value.min, '24-hours').split(':');
      const endTimeArr = convertMinutesInHours(value.max, '24-hours').split(':');
      dispatch(
        setTime({
          startTime: set(new Date(bookDay), {
            hours: +startTimeArr[0],
            minutes: +startTimeArr[1],
            seconds: 0,
          }),
          endTime: set(new Date(bookDay), {
            hours: +endTimeArr[0],
            minutes: +endTimeArr[1],
            seconds: 0,
          }),
        }),
      );
    }
  };

  const handelMouseDown = (value) => {
    //no active range buttons if we touch slider
    dispatch(setActiveInterval(null));
  };

  return (
    <div className="slider-container">
      <div className="slider_track" ref={sliderRef}>
        {bookings.map((el, i) => (
          <MultiRangeSlider
            key={`${el.max}${i}`}
            min={el.min}
            positionRange={getPositionRange(el.min, el.max)}
            max={el.max}
            widthRange={getWidthRange(el.min, el.max)}
            readOnly
            sendActionLogsBookingPage={sendActionLogsBookingPage}
          />
        ))}
        <MultiRangeSlider
          minCurrentRange={convertHoursInMinutes(redux.startTime)}
          maxCurrentRange={convertHoursInMinutes(redux.endTime)}
          positionRange={getPositionRange(workingHours[0]?.minutes, workingHours[workingHours.length - 1]?.minutes)}
          widthRange={getWidthRange(workingHours[0]?.minutes, workingHours[workingHours.length - 1]?.minutes)}
          min={workingHours[0]?.minutes}
          max={workingHours[workingHours.length - 1]?.minutes}
          onChange={handelChange}
          onMouseDown={handelMouseDown}
          sendActionLogsBookingPage={sendActionLogsBookingPage}
        />
      </div>
      <div className="slider_values">
        {workingHours.map((item) => (
          <div key={item.minutes} value={item.minutes}>
            {format(new Date(item.hour), 'h')}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SliderRange;
