import set from 'date-fns/set';
import { getHours, getMinutes } from 'date-fns';
import format from 'date-fns/format';
import isWithinInterval from 'date-fns/isWithinInterval';
import isToday from 'date-fns/isToday';

// enter range array (with string or object dates) and string or object date, return bool
const dateBeforeRange = (date, range, start) => {
  let rangeInstance = range.map((item) => ({
    start: new Date(item.startTime),
    end: new Date(item.endTime),
  }));
  const dateInstance = new Date(date);
  const startInstance = new Date(start);
  //filter range out before date books
  rangeInstance = rangeInstance.filter((item) => item.start > startInstance);
  //sort ragge by start date
  rangeInstance = rangeInstance.sort((a, b) => a.start - b.start);

  if (rangeInstance.length !== 0) {
    return startInstance < dateInstance && dateInstance <= rangeInstance[0].start;
  } else return startInstance < dateInstance;
};

// enter string or date object return array of date objects
const dayRangeGenerate = (day, interval, isRepeatBook) => {
  const dayOfRange = new Date(day);
  const currentDate = new Date();
  let range = [];
  for (let i = 0; i <= 23; i++) {
    for (let b = 0; b < 59; b += interval) {
      const date = new Date(day);
      date.setHours(i);
      date.setMinutes(b);
      date.setSeconds(0);
      date.setMilliseconds(0);
      range.push(date);
    }
  }

  if (dayOfRange.toDateString() === currentDate.toDateString() && !isRepeatBook) {
    range = range.filter((item) => {
      return item > currentDate;
    });
  }
  return range;
};

// enter range array (with string or object dates) and string or object date, return bool
const rangeIncludeDate = (date, range) => {
  const rangeInstance = range.map((item) => ({
    start: new Date(item.startTime),
    end: new Date(item.endTime),
  }));
  const dateInstance = new Date(date);

  return rangeInstance.some((item) => item.start <= dateInstance && dateInstance < item.end);
};

export const getListAvailableIntervalsStartTime = (day, existRange, interval = 15, isRepeatBook) => {
  let range = dayRangeGenerate(day, interval, isRepeatBook);
  range.pop(); // slice 23.45;
  range = range.filter((item) => !rangeIncludeDate(item, existRange));
  return range;
};

export const getListAvailableIntervalsEndTime = (day, existRange, start, interval = 15) => {
  let range = dayRangeGenerate(day, interval);
  // range.push(endOfDay(day)); //add end of day
  range = range.filter((item) => dateBeforeRange(item, existRange, start));
  return range;
};

function splitInterval(start, end, interval) {
  let result = [];
  for (let time = start; time < end; time += interval) {
    if (time + interval <= end) {
      result.push({
        startTime: new Date(time),
        endTime: new Date(time + interval),
      });
    }
  }
  return result;
}

export const convertHoursInMinutes = (time) => {
  const hour = Math.trunc(+getHours(new Date(time)) * 60);
  const minutes = +getMinutes(new Date(time));
  return hour + minutes;
};

export const convertMinutesInHours = (value, type) => {
  const hour = Math.trunc(value / 60);
  const minutes = value - hour * 60;
  const date = set(new Date(), {
    hours: hour,
    minutes: minutes,
    seconds: 0,
  });
  return type ? format(date, 'H:mm') : format(date, 'h:mm');
};

export const getAvailableTime = (bookings, bookDay, startDay, endDay, isSlider) => {
  let availableTimes = [];
  if (!bookings.length && new Date().getTime() < endDay.getTime()) {
    availableTimes.push({
      startTime: isToday(bookDay) && new Date() > startDay.getTime() ? new Date() : startDay,
      endTime: endDay,
    });
  } else {
    const booksTimeInWorkDay = bookings.filter(
      (book) =>
        isWithinInterval(new Date(book.startTime), {
          start: startDay,
          end: endDay,
        }) ||
        isWithinInterval(new Date(book.endTime), {
          start: startDay,
          end: endDay,
        }),
    );
    if (!booksTimeInWorkDay.length) {
      availableTimes.push({
        startTime: isToday(bookDay) ? new Date() : startDay,
        endTime: endDay,
      });
      return availableTimes;
    }
    for (let i = 0; i < booksTimeInWorkDay.length; i++) {
      const isLastItem = i === booksTimeInWorkDay.length - 1;
      const isFirstItem = i === 0;
      const valueStartTime = set(new Date(booksTimeInWorkDay[i].startTime), { seconds: 0, milliseconds: 0 });
      const valueEndTime = set(new Date(booksTimeInWorkDay[i].endTime), { seconds: 0, milliseconds: 0 });
      if (isFirstItem && valueEndTime.toString() > startDay.toString()) {
        if (valueStartTime.toString() > startDay.toString()) {
          availableTimes.push({
            startTime:
              isToday(valueStartTime) &&
              new Date().toString() > startDay.toString() &&
              !isSlider &&
              valueEndTime.toString() < endDay.toString()
                ? new Date()
                : startDay,
            endTime: valueStartTime,
          });
        }
        if (
          valueEndTime.toString() < endDay.toString() &&
          valueEndTime.toString() !== new Date(booksTimeInWorkDay[i + 1]?.startTime).toString()
        ) {
          availableTimes.push({
            startTime: valueEndTime,
            endTime: booksTimeInWorkDay[i + 1]?.startTime ? new Date(booksTimeInWorkDay[i + 1]?.startTime) : endDay,
          });
        }
      } else if (
        !isFirstItem &&
        !isLastItem &&
        valueEndTime.toString() !== new Date(booksTimeInWorkDay[i + 1]?.startTime).toString()
      ) {
        availableTimes.push({
          startTime: valueEndTime,
          endTime:
            new Date(booksTimeInWorkDay[i + 1].startTime).toString() < endDay.toString()
              ? new Date(booksTimeInWorkDay[i + 1].startTime)
              : endDay,
        });
      } else if (isLastItem && valueEndTime.toString() < endDay.toString()) {
        availableTimes.push({
          startTime: valueEndTime.toString() < startDay.toString() ? startDay : valueEndTime,
          endTime: endDay,
        });
      }
    }
  }
  return availableTimes;
};
