import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import UsersList from './UsersList';

const UserSelection = ({
  type,
  value,
  helperText,
  onClick,
  onChange,
  hasError,
  iconNode,
  placeholder,
  label,
  active,
  sendActionLogsBookingPage,
}) => {
  const [newValue, setNewValue] = useState(value);

  const handelCancel = (e) => {
    setNewValue(value);
    onClick(e, type);
  };
  const handelChange = (value) => {
    setNewValue(value);
  };
  return (
    <>
      <li
        className={`inputs_${type} ${active ? `active-block-${type}` : 'visible'} ${
          hasError(type) ? 'inputs_error' : ''
        }`}
      >
        <div className="label">
          <Trans i18nKey={label} />
        </div>
        <div className="input_wrapper">
          <div className="input_elem input_elem--users" onClick={(e) => (active ? '' : onClick(e, type))}>
            {!newValue.length ? (
              <span className="users_placeholder">
                <Trans i18nKey={placeholder} />
              </span>
            ) : (
              newValue.map((user) => `${user.firstName} ${user.lastName}`).join(', ')
            )}
          </div>
          <div className="input_icon">{iconNode}</div>
        </div>
        {helperText(type) && <div className="error">{helperText(type)}</div>}
        {active && (
          <div className="users_buttons">
            <button type="button" className="gradient-border" onClick={handelCancel}>
              <p className="users_buttons-cancel">
                <Trans i18nKey="form.users.btn_cancel" />
              </p>
            </button>
            <button type="button" className="users_buttons-done" onClick={() => onChange(newValue, type)}>
              <Trans i18nKey="form.users.btn_done" />
            </button>
          </div>
        )}
      </li>
      {active && (
        <div className="users_list">
          <UsersList
            value={newValue}
            type={type}
            onChange={handelChange}
            active={active}
            title={label}
            placeholder={placeholder}
            sendActionLogsBookingPage={sendActionLogsBookingPage}
          />
        </div>
      )}
    </>
  );
};

export default UserSelection;
