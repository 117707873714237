import { createSlice } from '@reduxjs/toolkit';
import { api } from '../services/http';
import * as Sentry from '@sentry/react';

const initialState = {
  info: {
    forGroup: false,
    number: '',
    title: '',
    filters: [],
    nfcId: null,
  },
};

const slice = createSlice({
  initialState,
  name: 'place',
  reducers: {
    startLoading() {},
    hasError(state, action) {
      Sentry.captureException(action.payload);
      console.error(action.payload);
    },
    getPlaceInfoSuccess(state, action) {
      state.info.forGroup = action.payload.forGroup;
      state.info.title = action.payload.title;
      state.info.number = action.payload.number;
      state.info.filters = action.payload.filters;
      state.info.nfcId = action.payload.nfcId;
    },
  },
});

// Reducer
export const placeReducer = slice.reducer;

// ----------------------------------------------------------------------

export const getPlaceInfo = (placeId) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    await api.get(`/v1/places/${placeId}/populated`).then((res) => {
      dispatch(slice.actions.getPlaceInfoSuccess(res));
    });
  } catch (err) {
    dispatch(slice.actions.hasError(err));
    Sentry.setExtra('Error Data', {
      status: err?.status,
      url: err?.url,
      body: err?.data,
    });
    Sentry.captureException(err);
  }
};
// ----------------------------------------------------------------------
