import { createSlice } from '@reduxjs/toolkit';
import { api } from '../services/http';
import * as Sentry from '@sentry/react';

const initialState = {
  availableList: [],
  bookingList: [],
  allBookingsList: [],
  activeInterval: null,
  isLoading: false,
  errors: null,
};

const slice = createSlice({
  initialState,
  name: 'booking',
  reducers: {
    startLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      Sentry.captureException(action.payload);
      console.error(action.payload);
      state.errors = action.payload?.data;
      state.isLoading = false;
    },
    getBookingsUsersSuccess(state, action) {
      const bookingsItems = action.payload.items;
      state.bookingList = bookingsItems.filter((place) => !place.checkOut).reverse();
      state.allBookingsList = bookingsItems
        .map((book) => {
          return {
            ...book,
            startTime: book.checkIn ? book.checkInTime : book.startTime,
            endTime: book.checkOut ? book.checkOutTime : book.endTime,
          };
        })
        .reverse();
      state.isLoading = false;
      state.errors = null;
    },
    setAvailableTimeList(state, action) {
      state.availableList = action.payload;
    },
    setActiveIntervalSuccess(state, action) {
      state.activeInterval = action.payload;
    },
    bookPlaceSuccess(state, action) {
      state.isLoading = false;
      state.errors = null;
    },
    setErrorText(state, action) {
      state.isLoading = false;
      state.errors = action.payload;
    },
  },
});

// Reducer
export const bookingReducer = slice.reducer;

// ----------------------------------------------------------------------

export const getBookingsUsers =
  ({ placeId, params }) =>
  async (dispatch) => {
    const dto = {
      [`dates[0][startTime]`]: params.startTime,
      [`dates[0][endTime]`]: params.endTime,
      places: [placeId],
      owners: 'any',
    };
    dispatch(slice.actions.startLoading());
    try {
      await api.get(`/v2/bookings`, dto).then((res) => {
        dispatch(slice.actions.getBookingsUsersSuccess(res));
      });
    } catch (err) {
      dispatch(slice.actions.hasError(err));
      Sentry.setExtra('Error Data', {
        status: err?.status,
        url: err?.url,
        body: err?.data,
      });
      Sentry.captureException(err);
    }
  };

export const setActiveInterval = (interval) => async (dispatch) => {
  dispatch(slice.actions.setActiveIntervalSuccess(interval));
};

export const bookPlace = (params) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    await api.post('/v1/admin/booking', params).then((res) => {
      dispatch(slice.actions.bookPlaceSuccess(res));
    });
  } catch (err) {
    dispatch(slice.actions.hasError(err));
    Sentry.setExtra('Error Data', {
      status: err?.status,
      url: err?.url,
      body: err?.data,
    });
    Sentry.captureException(err);
    throw err.data;
  }
};

export const bookCheckIn = (nfcId) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    await api.put(`/v1/booking/nfc/${nfcId}/checkin`).then((res) => {
      return res;
    });
  } catch (err) {
    dispatch(slice.actions.hasError(err));
    Sentry.setExtra('Error Data', {
      status: err?.status,
      url: err?.url,
      body: err?.data,
    });
    Sentry.captureException(err);
  }
};

export const setAvailableTimeList = (bookings) => async (dispatch) => {
  dispatch(slice.actions.setAvailableTimeList(bookings));
};

export const setErrorText = (msg) => async (dispatch) => {
  dispatch(slice.actions.setErrorText(msg));
};

// ----------------------------------------------------------------------
