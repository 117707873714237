import React, { useCallback, useEffect, useRef, useState } from 'react';
import './MultiRangeSlider.scss';
import { convertMinutesInHours } from '../services/utils/date';

const MultiRangeSlider = ({
  min,
  max,
  minCurrentRange,
  maxCurrentRange,
  onChange,
  widthRange,
  positionRange,
  readOnly,
  onMouseDown,
  sendActionLogsBookingPage,
}) => {
  const [minVal, setMinVal] = useState(minCurrentRange || min);
  const [maxVal, setMaxVal] = useState(maxCurrentRange || max);
  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);

  // Convert to percentage
  const getPercent = useCallback((value) => Math.round(((value - min) / (max - min)) * 100), [min, max]);

  useEffect(() => {
    if (minCurrentRange) {
      setMinVal(minCurrentRange);
    }
  }, [minCurrentRange]);

  useEffect(() => {
    if (maxCurrentRange) {
      setMaxVal(maxCurrentRange || max);
    }
  }, [maxCurrentRange]);

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    if (range.current && !readOnly) {
      range.current.parentNode.childNodes[2].style.left = `${minPercent - 2}%`;
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const maxPercent = getPercent(maxVal);
    if (range.current && !readOnly) {
      range.current.parentNode.lastChild.style.left = `${maxPercent - 2}%`;
    }
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    if (onChange) {
      onChange({ min: minVal, max: maxVal });
    }
  }, [minVal, maxVal]);

  return (
    <div className={`wrapper-slider`} style={{ left: positionRange }}>
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        onChange={(event) => {
          sendActionLogsBookingPage('change time in slider, min value');
          const value = Math.min(Number(event.target.value), maxVal);
          setMinVal(value);
          minValRef.current = value;
        }}
        className="thumb thumb--left"
        style={{
          zIndex: minVal > max - 100 && '5',
          width: widthRange,
          display: `${readOnly || minVal < min || minVal > max ? 'none' : 'block'}`,
        }}
        onMouseDown={onMouseDown && onMouseDown}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        onChange={(event) => {
          sendActionLogsBookingPage('change time in slider, max value');
          const value = Math.max(Number(event.target.value), minVal);
          setMaxVal(value);
          maxValRef.current = value;
        }}
        style={{ width: widthRange, display: `${readOnly || maxVal < min || maxVal > max ? 'none' : 'block'}` }}
        className="thumb thumb--right"
        onMouseDown={onMouseDown && onMouseDown}
      />
      <div className="slider" style={{ width: widthRange }}>
        <div className={`slider__track ${readOnly ? 'slider__track--bg' : ''}`} />
        <div ref={range} className={`slider__range ${readOnly ? 'slider__track--bg' : ''}`} />
        <div className="slider__left-value">
          {!readOnly && minVal >= min && minVal <= max && convertMinutesInHours(minVal)}
        </div>
        <div className="slider__right-value">
          {!readOnly && maxVal >= min && maxVal <= max && convertMinutesInHours(maxVal)}
        </div>
      </div>
    </div>
  );
};

export default MultiRangeSlider;
