import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import './UsersList.scss';
import { Check, User } from '../icons';
import Spinner from './Spinner';
import { Trans, useTranslation } from 'react-i18next';

const UsersList = ({ value, onChange, type, active, title, placeholder, sendActionLogsBookingPage }) => {
  const { t } = useTranslation();
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const inputRef = useRef(null);
  const redux = useSelector((state) => ({
    users: state.users.list,
    loadedUsers: state.users.loadedUsers,
  }));

  useEffect(() => {
    setFilteredUsers(redux.users);
    if (value) {
      setSelectedUsers(value);
    }
  }, [redux.users]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [active]);

  const handleSelectUser = (user) => {
    sendActionLogsBookingPage('click to select user in users list (not save)');
    let newArr = [];
    if (type === 'owner') {
      newArr = [user];
    } else {
      const findUser = selectedUsers.find((el) => el._id === user._id);
      newArr = [...selectedUsers];
      if (!findUser) {
        newArr.push(user);
      } else {
        newArr = newArr.filter((el) => el._id !== findUser._id);
      }
    }
    setSelectedUsers(newArr);
    onChange(newArr, type);
  };

  const filterUsers = (e) => {
    const value = e.target.value.toLowerCase().trim();
    const result = redux.users
      .filter(
        (user) =>
          user.firstName.toLowerCase().indexOf(value) !== -1 || user.lastName.toLowerCase().indexOf(value) !== -1,
      )
      .map((item) => item);
    setFilteredUsers(result);
  };

  return (
    <div className="users">
      <div className="users_title">
        <Trans i18nKey={title} />
      </div>
      <div className="users_wrapper">
        <div className="users_filter">
          <input
            ref={inputRef}
            type="text"
            placeholder={t(placeholder)}
            className="users_input"
            onChange={filterUsers}
          />
        </div>
        <div className="user user_list">
          {redux.loadedUsers ? (
            <Spinner />
          ) : (
            <>
              {filteredUsers.map((user) => (
                <div key={user._id} className="user_item" onClick={(e) => handleSelectUser(user)}>
                  <div className="user_avatar">
                    <div className="user_avatar--wrapper">
                      {user.avatar ? <img src={user.avatar} /> : <User fill="#fff" />}
                    </div>
                  </div>
                  <div className="user_info">
                    <div className="user_name">{`${user.firstName} ${user.lastName}`}</div>
                    <div className="user_position">{user.position}</div>
                  </div>
                  <div className="user_check">{selectedUsers.find((el) => el._id === user._id) && <Check />}</div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UsersList;
