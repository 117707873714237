import React, { useEffect, useState } from 'react';
import './localization';
import './styles/reset.scss';
import './App.scss';
import RoomPage from './pages/RoomPage';
import BookingPage from './pages/BookingPage';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import MainLayout from './pages/MainLayout';
import { useSelector } from 'react-redux';
import { DeviceAuthGuard, DeviceConnectRouter, DeviceGuestGuard } from '@inspace-org/react-auth';
import { PATH_APP } from './routes';
import isWithinInterval from 'date-fns/isWithinInterval';

function App() {
  const navigate = useNavigate();
  const redux = useSelector((state) => ({
    booking: state.booking,
    bookingList: state.booking.bookingList,
    placeId: state.place.placeId,
    floorId: state.place.floorId,
  }));
  const [openNotification, setOpenNotification] = useState(false);
  const location = useLocation();
  const [isAvailable, setIsAvailable] = useState(false);
  const handleConnection = (isSuccessfull) => (device) => {
    if (isSuccessfull) {
      navigate(PATH_APP.room);
    } else {
      navigate(PATH_APP.root);
    }
  };

  useEffect(() => {
    let timer;
    if (openNotification) {
      timer = setTimeout(() => {
        setOpenNotification(false);
        navigate('/room');
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [openNotification]);

  useEffect(() => {
    if (
      redux.bookingList.length &&
      isWithinInterval(new Date(), {
        start: new Date(redux.bookingList[0]?.startTime),
        end: new Date(redux.bookingList[0]?.endTime),
      })
    ) {
      setIsAvailable(false);
    } else {
      setIsAvailable(true);
    }
  }, [redux.bookingList]);

  return (
    <div className="App">
      <DeviceConnectRouter onSuccess={handleConnection(true)} onFailure={handleConnection(false)} />
      <MainLayout isAvailable={isAvailable} openNotification={openNotification}>
        <TransitionGroup style={{ height: '90%' }}>
          <CSSTransition key={location.key} classNames="left-to-right" timeout={1000}>
            <>
              <Routes location={location}>
                <Route path="/" element={<DeviceGuestGuard redirectTo={PATH_APP.room} />} />
                <Route
                  path="/room"
                  element={
                    <DeviceAuthGuard redirectTo={PATH_APP.root}>
                      <RoomPage isAvailable={isAvailable} placeId={redux.placeId} floorId={redux.floorId} />
                    </DeviceAuthGuard>
                  }
                />
                <Route
                  path="/booking"
                  element={
                    <DeviceAuthGuard redirectTo={PATH_APP.root}>
                      <BookingPage
                        placeId={redux.placeId}
                        floorId={redux.floorId}
                        setOpenNotification={setOpenNotification}
                      />
                    </DeviceAuthGuard>
                  }
                />
              </Routes>
            </>
          </CSSTransition>
        </TransitionGroup>
      </MainLayout>
    </div>
  );
}

export default App;
