import React, { useEffect, useState } from 'react';
import format from 'date-fns/format';
import './Header.scss';

const Header = ({ logoImg, isActiveBranding }) => {
  const [time, setTime] = useState('');
  const todayDate = new Date();
  const day = format(todayDate, 'E, LLL d');

  useEffect(() => {
    setClock();
    const interval = setInterval(setClock, 5000);
    return () => clearInterval(interval);
  }, []);

  const setClock = () => {
    setTime(format(new Date(), 'h:mm aa'));
  };

  return (
    <div className="row">
      <div className="item">
        <div className="date">
          <div className="date_time">{time}</div>
          <div className="date_today">{day}</div>
        </div>
      </div>
      <div className="item">
        <div className="logo">
          <img
            src={`${isActiveBranding && logoImg ? logoImg : `${process.env.PUBLIC_URL}/logo-white.png`}`}
            alt="logo"
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
