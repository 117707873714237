import React from 'react';

export const Users = (props) => {
  return (
    <svg width="42" height="33" viewBox="0 0 42 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M28.8845 15.7466C32.1615 15.7466 34.9424 12.8052 34.9424 8.99943C34.9424 5.28467 32.1464 2.43416 28.8845 2.43416C25.6075 2.43416 22.7965 5.33015 22.8115 9.02975C22.8115 12.8052 25.5925 15.7466 28.8845 15.7466ZM11.4923 16.0954C14.3635 16.0954 16.7836 13.5178 16.7836 10.2124C16.7836 6.96768 14.3334 4.49623 11.4923 4.49623C8.65127 4.49623 6.20104 7.01317 6.21607 10.2427C6.21607 13.5178 8.63624 16.0954 11.4923 16.0954ZM3.20965 29.8627H14.4837C12.8753 27.5429 14.604 22.9639 18.0012 20.265C16.3477 19.1885 14.1981 18.4 11.4923 18.4C4.77299 18.4 0.428711 23.3884 0.428711 27.4974C0.428711 28.9985 1.18032 29.8627 3.20965 29.8627ZM19.5044 29.8476H38.2345C40.7147 29.8476 41.5716 29.0895 41.5716 27.6945C41.5716 23.7978 36.6711 18.4304 28.8694 18.4304C21.0828 18.4304 16.1673 23.7978 16.1673 27.6945C16.1673 29.0895 17.0242 29.8476 19.5044 29.8476Z"
        fill="#A1AEB7"
      />
    </svg>
  );
};
